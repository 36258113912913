import React, { useEffect } from "react";
import "./menu1.css";
import { animated, useSpring, easings } from "@react-spring/web";
import { useSelector } from "react-redux";

export default function Menu1({ menu, style }) {
  const { category, meals } = menu;

  const { font, withButtons } = style;

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 1200,
    },
  });

  return (
    <animated.div className="menu1-cnt" style={{ ...springs }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2 className="menu1-category" style={font}>
          {category}
        </h2>
        {menu.categoryImg ? (
          <>
            <h2 className="menu1-category" style={font}>
              {category}
            </h2>
            <img
              style={{ width: "120px", border: "none" }}
              src={menu.categoryImg}
              alt={menu.categoryImg}
            />
          </>
        ) : (
          ""
        )}
      </div>
      <div style={{ height: withButtons ? "auto" : "auto" }}>
        {meals.map((obj, i) => {
          return obj.available ? (
            <div className="menu1-item-cnt" key={i}>
              <div className="menu1-item">
                <h2 className="menu1-item-title" style={font}>
                  {obj.name}
                </h2>
                {obj.desc ? (
                  <h3 className="menu1-desc" style={font}>
                    {obj.desc}
                  </h3>
                ) : (
                  ""
                )}
                {obj.note ? (
                  <span className="menu1-note" style={{ ...font }}>
                    · {obj.note}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <h2 className="menu1-price" style={font}>
                {obj.price}
              </h2>
            </div>
          ) : (
            ""
          );
        })}
      </div>
    </animated.div>
  );
}

import React, { useState } from "react";
import { addMember } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import { auth } from "../firebase/Setup";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export default function Contact() {
  const dispatch = useDispatch();
  const { name } = useParams();

  const [membersName, setMembersName] = useState("");
  const [membersNumber, setMembersNumber] = useState("");
  const [membersBirthday, setMembersBirthday] = useState("");
  const [terms, setTerms] = useState(false);
  const [otp, setOtp] = useState("");

  const [flag, setFlag] = useState(false);
  const [success, setSuccess] = useState(false);

  const { loading } = useSelector((state) => state.members);

  const generateRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
        theme: "light", // Theme of the reCAPTCHA widget (not applicable for invisible)
        language: "iw", // Language code for Hebrew
        hl: "iw",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+972" + membersNumber, appVerifier)
      .then((confirmationResult) => {
        setFlag(true);

        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        dispatch(
          addMember(name, {
            name: membersName,
            birthday: membersBirthday,
            number: membersNumber,
          })
        );
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
        alert("מספר נייד שגוי");
      });
  };

  const verifyOtp = () => {
    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          console.log(user);
          setSuccess(true);
          alert("ההרשמה התבצעה בהצלחה!");
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          alert("הזנת קוד שגוי, אנא נסה שוב!");
        });
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <h4>הצטרפו למועדון החברים שלנו ותהנו מהטבות בלעדיות</h4>
      <strong>שם:</strong>
      <input
        onChange={(e) => setMembersName(e.target.value)}
        required
        placeholder="הזן שם"
      />
      <strong>נייד:</strong>
      <input
        onChange={(e) => setMembersNumber(e.target.value)}
        type="tel"
        value={membersNumber}
        required
        placeholder="הזן מספר טלפון"
        style={{ direction: "ltr" }}
      />
      <strong>תאריך לידה:</strong>
      <input
        onChange={(e) => setMembersBirthday(e.target.value)}
        type="date"
        required
        placeholder="הזן תאריך לידה"
      />
      <div>
        <input
          onChange={(e) => setTerms(e.target.checked)}
          type="checkbox"
          required
        />{" "}
        <span style={{ fontSize: "12px" }}>
          קראתי ואני מסכים/מסכימה לתנאי השירות
        </span>
      </div>
      <button type="submit" disabled={!terms}>
        אישור
      </button>
    </form>
  );

  const renderOtpForm = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "290px",
      }}
    >
      <h3>הזן את הקוד שקיבלת</h3>
      <input onChange={(e) => setOtp(e.target.value)} placeholder="הזן כאן" />
      <button onClick={verifyOtp}>אישור</button>
    </div>
  );

  const rendersuccess = () => {
    return <h3>ההרשמה בוצעה בהצלחה!</h3>;
  };

  return (
    <div className="members">
      {success ? (
        rendersuccess()
      ) : loading ? (
        <Loader />
      ) : !flag ? (
        renderForm()
      ) : (
        renderOtpForm()
      )}

      <div id="recaptcha"></div>
    </div>
  );
}

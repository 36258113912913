import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer({ color }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        style={{
          color: "white",
          fontWeight: 600,
          height: "40px",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        onClick={() => navigate("/trail")}
      >
        <span style={{ color: color }}>2024 © Mealy</span>
      </button>
    </div>
  );
}

import React from "react";
import "./menu4.css";
import { animated, useSpring, easings } from "@react-spring/web";

export default function Menu4({ menu, style }) {
  const { category, titles, meals, img } = menu;

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 1500,
    },
  });

  return (
    <animated.div className="menu4-cnt" style={{ ...springs }}>
      <h2 className="menu4-category" style={style.font}>
        {category}
      </h2>
      {titles.map((title) => {
        return (
          <h3 className="menu4-titles" style={style.font}>
            {title}
          </h3>
        );
      })}
      <div className="menu4-meals-cnt">
        <img
          className="menu4-img"
          src={img.src}
          alt={img.alt}
          style={img.style}
        />
        {meals.map((obj) => {
          return (
            <h3 className="menu4-item-name" style={style.font}>
              {obj.name}
            </h3>
          );
        })}
      </div>
    </animated.div>
  );
}

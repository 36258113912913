import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editGeneralSettings } from "../../actions/settingsAction";

export default function GeneralSettings() {
  const dispatch = useDispatch();

  const business = useSelector((state) => state.business);
  const { info } = business;

  return (
    <div style={{ display: "flex", padding: "10px", flexDirection: "column" }}>
      <h2>הגדרות כלליות</h2>
      <div>
        <h3>שם העסק</h3>
        <input value={info.name} />
        <button onClick={() => dispatch(editGeneralSettings())}>אישור</button>
      </div>
    </div>
  );
}

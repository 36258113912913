import React from "react";
import { useSelector } from "react-redux";
import "./members.css";

export default function Members() {
  const members = useSelector((state) => state.members);

  return (
    <div className="setting-card">
      <h2 style={{ marginBottom: "20px" }}>חברי מועדון</h2>
      <table className="members-table">
        <thead>
          <tr>
            <th>שם</th>
            <th>מספר טלפון</th>
            <th>תאריך לידה</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr key={index}>
              <td>{member.name}</td>
              <td>{member.number}</td>
              <td>{member.birthday}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import React from "react";
import { animated, useSpring, easings } from "@react-spring/web";
import logo from "../uploads/logo3.png";

export default function Logo() {
  const springs = useSpring({
    from: { y: -280 },
    to: { y: 0 },
    config: {
      duration: 1500,
      easing: easings.easeOutBack,
    },
  });
  return (
    <animated.div className="home-logo card" style={{ ...springs }}>
      <img src={logo} alt="logo" style={{ width: "220px" }} />
      {/* <h3 style={{ color: "white", padding: "10px" }}>התפריט לעסק שלך</h3> */}
    </animated.div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStyle } from "../actions/settingsAction";

export default function StyleSettings() {
  const dispatch = useDispatch();
  const style = useSelector((state) => state.style);
  const { loading } = style;

  const [backgroundType, setBackgroundType] = useState(
    style.background.type || "color"
  );
  const [fontColor, setFontColor] = useState(style.font.color || "black");
  const [fontFamily, setFontFamily] = useState(
    style.font.fontFamily || "system-ui"
  );
  const [background, setBackground] = useState(style.background.url || "");
  const [logo, setLogo] = useState(style.logo.url || "");
  const [withButtons, setWithButtons] = useState(style.withButtons || 0);

  const handleBackgroundTypeChange = (e) => {
    setBackgroundType(e.target.value);
  };

  const fontFamilyArr = [
    "auto",
    "system-ui",
    "Open Sans",
    "Rubik",
    "Suez One",
    "Varela Round",
  ];

  const fontStyles = {
    fontFamily: fontFamily,
    color: fontColor,
    border: "1px solid black",
    padding: "20px",
    width: "80%",
    textAlign: "center",
    marginBottom: "15px",
  };

  if (loading) {
    return <h1>loading...</h1>;
  }

  return (
    <div className="setting-card">
      <h2 style={{ marginBottom: "20px" }}>עיצוב תפריט</h2>
      <div style={style}>
        <h3>הוסף לוגו:</h3>
        <input
          type="url"
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
        />
      </div>

      <div style={style}>
        <h3>צבע פונט:</h3>
        <input
          type="color"
          value={fontColor}
          onChange={(e) => setFontColor(e.target.value)}
        />
      </div>

      <div style={style}>
        <h3>סוג פונט:</h3>
        <select
          value={fontFamily}
          onChange={(e) => setFontFamily(e.target.value)}
        >
          {fontFamilyArr.map((obj) => (
            <option key={obj} value={obj}>
              {obj}
            </option>
          ))}
        </select>
      </div>

      <h2 style={fontStyles}>דוגמה לסוג פונט</h2>

      <div style={{ display: "flex" }}>
        <h3 style={{ marginLeft: "10px" }}>רקע:</h3>
        <input
          type="radio"
          name="backgroundType"
          value="color"
          checked={backgroundType === "color"}
          onChange={handleBackgroundTypeChange}
        />
        <label style={{ marginLeft: "5px", fontWeight: 600 }} htmlFor="color">
          צבע
        </label>

        <input
          type="radio"
          name="backgroundType"
          value="image"
          checked={backgroundType === "image"}
          onChange={handleBackgroundTypeChange}
        />
        <label style={{ fontWeight: 600 }} htmlFor="image">
          תמונה
        </label>
      </div>

      {backgroundType === "color" && (
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-around",
            width: "80%",
          }}
        >
          <h3 style={mb}>בחירת צבע:</h3>
          <input
            type="color"
            value={background}
            onChange={(e) => {
              console.log(background);
              setBackground(e.target.value);
            }}
          />
        </div>
      )}

      {backgroundType === "image" && (
        <div style={{ marginBottom: "10px" }}>
          <h3 style={mb}>הוספת קישור לתמונה:</h3>
          <input
            type="url"
            placeholder="הזן כתובת URL לתמונה"
            value={background}
            onChange={(e) => setBackground(e.target.value)}
          />
        </div>
      )}
      <div>
        <h3>תפריט עם כפתורים</h3>
        <input
          type="checkbox"
          checked={withButtons}
          onChange={(e) => setWithButtons(e.target.checked ? 1 : 0)}
        />
      </div>

      <button
        onClick={() =>
          dispatch(
            editStyle({
              ...style,
              background: {
                type: backgroundType,
                url: background,
                style: "",
              },
              font: { color: fontColor, fontFamily },
              logo: {
                url: logo,
                style: "",
              },
              withButtons: withButtons,
            })
          )
        }
      >
        אישור
      </button>
    </div>
  );
}

const style = {
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
  marginBottom: "10px",
};

const mb = {
  marginBottom: "10px",
};

import React from "react";
import "./menu3.css";
import { animated, useSpring, easings } from "@react-spring/web";

export default function Menu3({ menu, style }) {
  const { category, meals } = menu;

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 1500,
    },
  });
  return (
    <animated.div className="menu3-cnt" style={{ ...springs }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {menu.categoryImg ? (
          <>
            <h2 className="menu3-category" style={style.font}>
              {category}
            </h2>
            <img
              style={{ width: "120px" }}
              src={menu.categoryImg}
              alt={menu.categoryImg}
            />
          </>
        ) : (
          <h2
            style={{ width: "100%", ...style.font }}
            className="menu3-category"
          >
            {category}
          </h2>
        )}
      </div>
      {meals.map((obj) => {
        return obj.available ? (
          <div className="menu3-card">
            <div>
              <img className="menu3-item-img" src={obj.img} alt={obj.img} />
            </div>
            <div className="menu3-title-cnt">
              <h2 className="menu3-item-name" style={style.font}>
                {obj.name}
              </h2>
              <h3 className="menu3-item-desc" style={style.font}>
                {obj.desc}
              </h3>
              {obj.note ? (
                <span className="menu1-note" style={{ ...style.font }}>
                  · {obj.note}
                </span>
              ) : (
                ""
              )}
            </div>
            <h2 className="menu3-item-price" style={style.font}>
              {obj.price}
            </h2>
          </div>
        ) : (
          ""
        );
      })}
    </animated.div>
  );
}

import React, { useState } from "react";
import "./alma-phone.css";
import { auth } from "../../firebase/Setup";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

export default function AlmaPhone() {
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState("");
  const [otp, setOtp] = useState(null);

  const sendOtp = async () => {
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible", // Make reCAPTCHA widget invisible
        theme: "light", // Theme of the reCAPTCHA widget (not applicable for invisible)
        language: "iw", // Language code for Hebrew
        hl: "iw", // Language code for Hebrew, similar to 'language', 'hl' takes precedence if both are provided
        badge: "inline", // Position of the reCAPTCHA badge (not applicable for invisible)
        callback: () => {}, // Callback function to execute when reCAPTCHA verification succeeds
        "expired-callback": () => {}, // Callback function to execute when reCAPTCHA response expires
        "error-callback": () => {}, // Callback function to execute when an error occurs during reCAPTCHA execution
      });

      const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha);
      setUser(confirmation);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async () => {
    try {
      const data = user.confirm(otp);
      console.log(user);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="alma-phone-cnt">
      <div
        className="card"
        style={{
          marginBottom: "80px",
          justifyContent: "space-evenly",
          height: "315px",
          width: "335px",
        }}
      >
        <h3>הזן מספר פלאפון:</h3>
        <input
          placeholder="הזן כאן"
          onChange={(e) => setPhone(e.target.value)}
        />
        <button onClick={sendOtp}>שלח לקבלת קוד</button>
        <input onChange={(e) => setOtp(e.target.value)} placeholder="הזן קוד" />
        <button onClick={verifyOtp}>אישור</button>
      </div>
      <div id="recaptcha"></div>
    </div>
  );
}

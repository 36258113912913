import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { editMenuAction } from "../actions/businessAction";
import { IoEyeOff, IoEye } from "react-icons/io5";
import Loader from "./Loader";

export default function ChangeCategoryPlace() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { loading } = menu;

  const [editMenu, setEditMenu] = useState(menu);

  const [visibleCategories, setVisibleCategories] = useState(
    new Array(editMenu.length).fill(true)
  );

  const handleMoveCategory = (index, direction) => {
    const updatedMenu = [...editMenu];
    const currentIndex = index;
    const newIndex = direction === "up" ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < updatedMenu.length) {
      // Swap categories in the array
      [updatedMenu[currentIndex], updatedMenu[newIndex]] = [
        updatedMenu[newIndex],
        updatedMenu[currentIndex],
      ];
      setEditMenu(updatedMenu);
    }
  };

  const handleToggleVisibility = (index) => {
    let update = menu;
    update[index].available = !update[index].available;
    setEditMenu([...update]);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="setting-card">
      <h2 style={{ marginBottom: "20px" }}>שינוי מיקום קטגוריה</h2>
      {editMenu.map((obj, index) => (
        <div
          key={index}
          style={{
            height: "70px",
            width: "80%",
            display: "flex",
            marginBottom: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontWeight: 600,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
              padding: "5px",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <button onClick={() => handleMoveCategory(index, "up")}>
              <BiUpArrow />
            </button>
            <button onClick={() => handleMoveCategory(index, "down")}>
              <BiDownArrow />
            </button>
          </div>

          <span style={{ marginRight: "10px" }}>{obj.category}</span>
          {obj.available ? (
            <IoEye onClick={() => handleToggleVisibility(index)} />
          ) : (
            <IoEyeOff onClick={() => handleToggleVisibility(index)} />
          )}
        </div>
      ))}
      <button onClick={() => dispatch(editMenuAction(editMenu))}>אישור</button>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./menu2.css";
import { useDispatch, useSelector } from "react-redux";
import Meal from "../components/Meal";
import { useNavigate, useParams } from "react-router-dom";
import { getMenu } from "../actions/userActions";
import Loader from "../components/Loader";
import { FaHome } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import HomeFooter from "../components/HomeFooter";

const Menu2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useParams();

  const menu = useSelector((state) => state.menu);
  const business = useSelector((state) => state.business);
  const style = useSelector((state) => state.style);

  const [parentIndex, setParentIndex] = useState(0);
  const [categoryIndex, setCategoryIndex] = useState(0);

  useEffect(() => {
    dispatch(getMenu(name));
  }, [name, dispatch]);

  if (!menu || !style) {
    return <Loader />;
  }

  const { logo, background, parentButton, childButton, meal } = style;

  const filterMenu = (menu) => {
    return menu
      .filter((parentCategory) => parentCategory.available) // Filter unavailable parent categories
      .map((parentCategory) => {
        if (parentCategory.categories) {
          return {
            ...parentCategory,
            categories: parentCategory.categories
              .filter((category) => category.available) // Filter unavailable child categories
              .map((category) => ({
                ...category,
                meals: category.meals.filter((meal) => meal.available),
              })),
          };
        } else {
          return {
            ...parentCategory,
            meals: parentCategory.meals.filter((meal) => meal.available),
          };
        }
      });
  };

  const filteredMenu = filterMenu(menu);

  const hasCategories = filteredMenu[parentIndex]?.categories;

  return (
    <div className="menu-cnt" style={{ background: background.color }}>
      <FaHome className="home-btn" onClick={() => navigate(`/${name}`)} />
      <img src={logo.url} alt="Logo" className="menu-logo" style={logo.style} />
      {business?.info?.name === name ? (
        <GrUserManager
          className="manager-btn"
          onClick={() => navigate("/settingspro")}
        />
      ) : (
        ""
      )}
      <div className="menu-btn-cnt2">
        {filteredMenu.map((obj, i) => (
          <button
            style={parentButton.style}
            onClick={() => {
              setParentIndex(i);
              setCategoryIndex(0);
            }}
            key={i}
          >
            {obj.parentCategory}
          </button>
        ))}
      </div>

      {hasCategories && (
        <div
          className="menu-btn-cnt2"
          style={{ marginTop: "10px", paddingRight: "10px" }}
        >
          {filteredMenu[parentIndex].categories.map((obj, i) => (
            <button
              onClick={() => setCategoryIndex(i)}
              style={childButton.style}
              key={i}
            >
              {obj.category}
            </button>
          ))}
        </div>
      )}

      <div className="meals-cnt">
        <h2 style={{ color: "wheat", marginBottom: "10px" }}>
          {hasCategories
            ? filteredMenu[parentIndex]?.categories?.[categoryIndex]?.category
            : filteredMenu[parentIndex]?.parentCategory}
        </h2>
        {hasCategories
          ? filteredMenu[parentIndex]?.categories?.[categoryIndex]?.meals?.map(
              (obj) => <Meal meal={obj} key={obj.name} style={meal.style} />
            )
          : filteredMenu[parentIndex]?.meals?.map((obj) => (
              <Meal meal={obj} key={obj.name} style={meal.style} />
            ))}
      </div>
      <HomeFooter />
    </div>
  );
};

export default Menu2;

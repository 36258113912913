import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MealConstructor from "./MealConstructor";
import { editMenuAction } from "../actions/businessAction";
import { BsXCircleFill } from "react-icons/bs";
import { IoEyeOff, IoEye } from "react-icons/io5";
import "./edit-menu.css";

export default function EditMenuSettings() {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { loading } = menu;

  const [editedMenu, setEditedMenu] = useState(menu);

  const [category, setCategory] = useState(menu?.[0]?.category || "");
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [pricesTitle, setPricesTitle] = useState(menu?.[0]?.pricesTitle || "");
  const [mealObj, setMealObj] = useState({
    name: "",
    desc: "",
    price: "",
    note: "",
  });
  let obj = {};
  const [meals, setMeals] = useState(menu?.[0]?.meals || []);
  const [type, setType] = useState(menu?.[0]?.type || "");
  const [mealAvailable, setMealAvailable] = useState("סמן מנה כ'לא זמינה'");
  const [menuUpdated, setMenuUpdated] = useState(menu);

  const handleMealChange = (field, value, index) => {
    setMeals((prevMeals) => {
      const updatedMeals = [...prevMeals];
      updatedMeals[index] = { ...updatedMeals[index], [field]: value };

      setEditedMenu((prev) => {
        const updatedMenu = [...prev];
        updatedMenu[categoryIndex].meals = updatedMeals;
        return updatedMenu;
      });

      return updatedMeals;
    });
  };

  const handleDeleteMeals = (index) => {
    setMeals((prevMeals) => {
      const updatedMeals = prevMeals.filter((_, i) => i !== index);
      setEditedMenu((prev) => {
        const updatedMenu = [...prev];
        updatedMenu[categoryIndex].meals = updatedMeals;
        return updatedMenu;
      });
      return updatedMeals;
    });
  };

  const handleAddMeal = () => {
    let obj = {};
    switch (type) {
      case "1":
        setMealObj({ name: "", desc: "", price: 0, note: "" });
      case "2":
        let prices = [];
        for (let index = 0; index < pricesTitle.length; index++) {
          prices.push("");
        }
        obj = { name: "", prices, available: 1 };
      case "3":
      case "4":
      default:
        break;
    }
    setMeals([...meals, obj]);
    setEditedMenu((prev) => {
      const update = [...prev];
      update[categoryIndex].meals = [...meals, obj];
      return update;
    });
  };

  const addAvailable = () => {
    setEditedMenu((prev) => {
      const update = [...prev];
      update[0].meals[0].available = 0;
      return update;
    });
  };

  const setAvailablity = (i) => {
    setEditedMenu((prev) => {
      const update = [...prev];
      update[categoryIndex].meals[i].available =
        !update[categoryIndex].meals[i].available;

      return update;
    });
  };

  const handleEditCategory = (e) => {
    setCategory(e);
    setEditedMenu((prev) => {
      const update = [...prev];
      update[categoryIndex].category = e;
      return update;
    });
  };

  const handleSelectCategory = (e) => {
    setCategory(menu[e.target.value].category);
    setCategoryIndex(e.target.value);
    setType(menu[e.target.value].type);
    setMeals(menu[e.target.value].meals);
    setPricesTitle(menu[e.target.value].pricesTitle);
    switch (e.target.value) {
      case "1":
        setMealObj({ name: "", desc: "", price: 0, note: "" });
      case "2":
        setMealObj({ name: "", prices: [] });
      case "3":
      case "4":
      default:
        break;
    }
  };

  const handleChangePrices = (e, i, index) => {
    setMeals((prevMeals) => {
      const updatedMeals = [...prevMeals];
      updatedMeals[i].prices[index] = e.target.value;
      return updatedMeals;
    });
  };

  const handleMealAvailable = (e) => {
    setMealAvailable("סמן מנה כ'זמינה'");
  };

  useEffect(() => {
    switch (menu[0].type) {
      case "1":
        setMealObj({ name: "", desc: "", price: "", note: "" });
        break;
      case "2":
        let prices = [];
        for (let index = 0; index < pricesTitle.length; index++) {
          prices.push("");
        }
        setMealObj({ name: "", prices });
        break;
      default:
        break;
    }
  }, []);

  if (loading) {
    return <h3>loading...</h3>;
  }

  return (
    <div className="setting-card">
      <h2 className="title">עריכת תפריט</h2>
      <h3 className="subtitle">בחר קטגוריה לעריכה:</h3>
      <select onChange={(e) => handleSelectCategory(e)} title="food">
        {menu.map((obj, i) => {
          return <option value={i}>{obj.category}</option>;
        })}
      </select>
      <div style={{ margin: "20px 0" }}>
        <h4>שנה שם קטגוריה:</h4>
        <input
          value={category}
          placeholder="הזן כאן"
          onChange={(e) => handleEditCategory(e.target.value)}
        />
      </div>
      {type == 2 ? (
        <div style={{ display: "flex", width: "180px" }}>
          {menuUpdated[categoryIndex].pricesTitle.map((obj, i) => {
            return (
              <input
                onChange={(e) => {
                  setMenuUpdated((prev) => {
                    const updatedMenu = [...prev];
                    updatedMenu[categoryIndex].pricesTitle[i] = e.target.value;
                    return updatedMenu;
                  });
                }}
                value={obj}
              />
            );
          })}
        </div>
      ) : (
        ""
      )}

      {menuUpdated[categoryIndex].meals.map((obj, i) => {
        switch (type) {
          case "1":
            return (
              <div key={i} className="edit-menu1">
                <h6 style={{ margin: "0" }}>שם מנה:</h6>
                <input
                  value={obj.name}
                  placeholder="הזן שם (חובה) "
                  onChange={(e) => handleMealChange("name", e.target.value, i)}
                />
                <h6>תיאור:</h6>
                <textarea
                  value={obj.desc}
                  placeholder="הזן תיאור (לא חובה) "
                  onChange={(e) => handleMealChange("desc", e.target.value, i)}
                />
                <h6>הערה:</h6>

                <input
                  placeholder="הזן הערה (לא חובה) "
                  onChange={(e) => handleMealChange("note", e.target.value, i)}
                  value={"הזן כאן" && obj.note}
                />
                <h6>מחיר:</h6>

                <input
                  placeholder=" הזן מחיר"
                  value={obj.price}
                  onChange={(e) => handleMealChange("price", e.target.value, i)}
                />
                <button
                  style={{ width: "100%", marginTop: "15px" }}
                  onClick={() => setAvailablity(i)}
                >
                  {obj.available ? (
                    <IoEye style={{ margin: "0 0px 3px 10px" }} />
                  ) : (
                    <IoEyeOff style={{ margin: "0 0px 3px 10px" }} />
                  )}
                  {obj.available ? "סמן מנה כלא זמינה" : "סמן מנה כזמינה"}
                </button>
                <button
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    backgroundColor: "red",
                  }}
                  onClick={(e) => handleDeleteMeals(i)}
                >
                  מחק מנה
                </button>
              </div>
            );
          case "2":
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <button className="btn" onClick={() => setAvailablity(i)}>
                  {obj.available ? (
                    <IoEye className="eye-svg" />
                  ) : (
                    <IoEyeOff className="eye-svg" />
                  )}
                </button>
                <input
                  value={obj.name}
                  style={{ width: "100px" }}
                  placeholder="הזן שם"
                  onChange={(e) => handleMealChange("name", e.target.value, i)}
                />
                <div>
                  {obj.prices.map((price, index) => {
                    return (
                      <input
                        value={price}
                        style={{ width: "35px" }}
                        onChange={(e) => handleChangePrices(e, i, index)}
                        placeholder="מחיר"
                      />
                    );
                  })}
                </div>
                <button className="btn" onClick={() => handleDeleteMeals(i)}>
                  <BsXCircleFill className="delete-svg" />
                </button>
              </div>
            );
          case "3":
            return (
              <div key={i} className="edit-menu1">
                <h6 style={{ margin: "0" }}>שם מנה:</h6>
                <input
                  value={obj.name}
                  placeholder="הזן שם (חובה) "
                  onChange={(e) => handleMealChange("name", e.target.value, i)}
                />
                <h6>תיאור:</h6>
                <textarea
                  value={obj.desc}
                  placeholder="הזן תיאור (לא חובה) "
                  onChange={(e) => handleMealChange("desc", e.target.value, i)}
                />
                <h6>שנה תמונה:</h6>

                <input
                  placeholder="הזן הערה (לא חובה) "
                  onChange={(e) => handleMealChange("img", e.target.value, i)}
                  value={"הזן כאן" && obj.img}
                />
                <h6>שנה הערה:</h6>

                <input
                  placeholder="הזן הערה (לא חובה) "
                  onChange={(e) => handleMealChange("note", e.target.value, i)}
                  value={"הזן כאן" && obj.note}
                />
                <h6>מחיר:</h6>
                <input
                  placeholder=" הזן מחיר"
                  value={obj.price}
                  onChange={(e) => handleMealChange("price", e.target.value, i)}
                />
                <button
                  style={{ width: "100%", marginTop: "15px" }}
                  onClick={() => setAvailablity(i)}
                >
                  {obj.available ? (
                    <IoEye style={{ margin: "0 0px 3px 10px" }} />
                  ) : (
                    <IoEyeOff style={{ margin: "0 0px 3px 10px" }} />
                  )}
                  {obj.available ? "סמן מנה כלא זמינה" : "סמן מנה כזמינה"}
                </button>
                <button
                  style={{
                    width: "100%",
                    marginTop: "15px",
                    backgroundColor: "red",
                  }}
                  onClick={(e) => handleDeleteMeals(i)}
                >
                  מחק מנה
                </button>
              </div>
            );
          case "4":
            return <h1>type4</h1>;
          default:
            return <h1>...</h1>;
        }
      })}
      <div className="buttons-cnt">
        <button className="add-meal" onClick={() => handleAddMeal()}>
          הוספת מנה
        </button>
        <button
          className="edit-accept"
          onClick={() => dispatch(editMenuAction(editedMenu))}
        >
          אישור
        </button>
      </div>
    </div>
  );
}

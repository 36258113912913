import React from "react";
import Logo from "../Logo";

export default function Hero() {
  return (
    <div className="hero-cnt ">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontSize: "45px",
            textShadow: "rgb(255 255 255 / 25%) 2px 1px 4px",
          }}
          className="gradient-text"
        >
          תפריט ששומר
          <br />
          איתך על קשר
        </h2>
        <a className="contact-btn" href="#contact">
          לחץ ליצירת קשר
        </a>
      </div>
      <Logo />
      {/* <img style={{ height: "250px" }} src={qr} alt={"qr"} /> */}
    </div>
  );
}

import axios from "axios";
import {
  BUSINESS_GS_EDIT_FAIL,
  BUSINESS_GS_EDIT_REQUEST,
  BUSINESS_GS_EDIT_SUCCESS,
  BUSINESS_STYLE_FAIL,
  BUSINESS_STYLE_REQUEST,
  BUSINESS_STYLE_SUCCESS,
} from "../constants/settingsConstant";

export const editGeneralSettings = (general) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_GS_EDIT_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.put(
      "/api/business/general",
      { general },
      config
    );

    dispatch({ type: BUSINESS_GS_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_GS_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editStyle = (style) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_STYLE_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };
    const { data } = await axios.put("/api/business/style", style, config);

    localStorage.setItem("style", JSON.stringify(data));
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_STYLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./settings.css";
import CategorySettings from "../components/CategorySettings";
import EditMenuSettings from "../components/EditMenuSettings";
import StyleSettings from "../components/StyleSettings";
import BannerSettings from "../components/BannerSettings";
import Loader from "../components/Loader";
import MenuManager from "../components/settings/MenuManager";
import BannerManager from "../components/settings/BannerManager";
import TablesManager from "../components/settings/TablesManager";
import FansManager from "../components/settings/FansManager";
import GeneralSettings from "../components/settings/GeneralSettings";
import MenuProManager from "../components/MenuProManager";

export default function SettingsPro() {
  const navigate = useNavigate();

  const business = useSelector((state) => state.business);
  const { info } = business;

  const menu = useSelector((state) => state.menu);
  const { loading } = menu;

  const style = useSelector((state) => state.style);
  const { background, parentButton, font, childButton } = style;

  const [type, setType] = useState("menu");

  const [greeting, setGreeting] = useState("שלום");

  const renderActiveComponent = () => {
    switch (type) {
      case "menu":
        return <MenuProManager style={style} menu={menu} />;
      case "tables":
        return <TablesManager />;
      case "fans":
        return <FansManager />;
      case "general":
        return <GeneralSettings />;
      default:
        return <MenuManager />;
    }
  };

  useEffect(() => {
    if (business && info) {
    } else {
      navigate("/login");
    }

    const getCurrentTime = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting("בוקר טוב");
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting("צהריים טובים");
      } else {
        setGreeting("ערב טוב");
      }
    };

    getCurrentTime();
    const interval = setInterval(getCurrentTime, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ background: background.color, minHeight: "100vh", ...font }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <h1 style={{ padding: "10px" }}>{greeting}</h1>
        <h1 style={{ padding: "10px" }}>{info.name}</h1>
      </div>
      <div className="settings-btn-cnt">
        <button style={parentButton.style} onClick={() => setType("menu")}>
          הגדרות תפריט
        </button>
        <button style={parentButton.style} onClick={() => setType("tables")}>
          שולחנות
        </button>
        <button style={parentButton.style} onClick={() => setType("fans")}>
          חברי מועדון
        </button>
        <button
          style={parentButton.style}
          onClick={() => navigate(`/${info.name}/menu2`)}
        >
          לתפריט
        </button>
      </div>
      {renderActiveComponent()}
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStyle } from "../actions/settingsAction";
import Loader from "./Loader";
import { BsXCircleFill } from "react-icons/bs";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";

export default function BannerSettings() {
  const dispatch = useDispatch();

  const style = useSelector((state) => state.style);
  const { loading, banner } = style;

  const handleRemoveBanner = (index) => {
    const newBanner = [...banner.stories];
    newBanner.splice(index, 1); // Remove the banner at the given index
    dispatch(
      editStyle({ ...style, banner: { ...banner, stories: newBanner } })
    );
  };

  const handleMoveBanner = (index, direction) => {
    const newBanner = [...banner.stories];
    if (direction === "up" && index > 0) {
      // Swap the current banner with the one above it
      [newBanner[index], newBanner[index - 1]] = [
        newBanner[index - 1],
        newBanner[index],
      ];
    } else if (direction === "down" && index < newBanner.length - 1) {
      // Swap the current banner with the one below it
      [newBanner[index], newBanner[index + 1]] = [
        newBanner[index + 1],
        newBanner[index],
      ];
    }
    dispatch(
      editStyle({ ...style, banner: { ...banner, stories: newBanner } })
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="setting-card" style={{ minHeight: "600px" }}>
      <h2>העלאת באנר</h2>
      <h5>הוסף קישור לבאנר</h5>
      <input placeholder="הזן כאן" />
      <button>הוסף תמונה</button>
      <span>יחס גודל מומלץ רוחב 4 על 3</span>

      {banner.stories.map((obj, index) => {
        return (
          <div
            style={{
              display: "flex",
              height: "250px",
              padding: "10px",
              width: "85%",
              justifyContent: "space-around",
            }}
            key={index}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BsXCircleFill
                style={{
                  height: "20px",
                  width: "20px",
                  color: "red",
                  marginBottom: "25px",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveBanner(index)}
              />
            </div>
            <img src={obj} style={{ marginRight: "10px", flex: "0 0 auto" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BiUpArrow
                style={{
                  height: "20px",
                  width: "20px",
                  marginBottom: "25px",
                }}
                onClick={() => handleMoveBanner(index, "up")}
              />
              <BiDownArrow
                style={{ height: "20px", width: "20px" }}
                onClick={() => handleMoveBanner(index, "down")}
              />
            </div>
          </div>
        );
      })}

      {/* <div>
        <span>הצג באנר:</span>
        <input checked={banner.show} type="checkbox" />
      </div> */}
      <button onClick={() => dispatch(editStyle({ ...style, banner }))}>
        אישור
      </button>
    </div>
  );
}

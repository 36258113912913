import React from "react";
import Contact from "../Contact";

export default function MenuContact() {
  return (
    <div className="menu-contact-cnt">
      <div className="contact-title">
        <h3>
          בנוסף לכל זה דאגנו לכם למאגר לקוחות שתוכלו לשמור על קשר על הקהל שלכם
        </h3>
      </div>
      <Contact />
    </div>
  );
}

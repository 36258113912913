import React, { useState } from "react";
import styles from "./background.css";

export default function ContactHome() {
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, phone, businessName);
  };
  return (
    <div className="contact-home-cnt" id="contact">
      <span>אז למה אתן מחכים?!</span>
      <h2>
        תשאירו כאן פרטים ואנחנו ניצור לכם את התפריט{" "}
        <span className="gradient-text"> המושלם </span>לעסק שלכם
      </h2>
      <form onSubmit={handleSubmit}>
        <input onChange={(e) => setName(e.target.value)} placeholder="הזן שם" />
        <input
          onChange={(e) => setBusinessName(e.target.value)}
          placeholder="הזן את שם העסק"
        />
        <input
          onChange={(e) => setPhone(e.target.value)}
          placeholder="הזן טלפון"
        />
        <button type="submit">אישור</button>
      </form>
    </div>
  );
}

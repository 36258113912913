import React from "react";
import "./menu-buttons.css";

export default function MenuButtons({ categories }) {
  return (
    <div className="menu-btn-cnt">
      {categories.map((obj) => {
        return <button style={{ width: "120px" }}>{obj}</button>;
      })}
    </div>
  );
}

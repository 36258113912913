import React from "react";
import OptionTab from "./OptionTab";
import eyeClose from "../../svg/eye-close.svg";
import appStore from "../../svg/app-store.svg";
import edit from "../../svg/edit.svg";
import art from "../../svg/art.svg";

export default function InMenu() {
  return (
    <div className="content-options">
      <h3
        style={{
          textShadow: "#ffb800 0 0 7px",
          color: "#fffad4",
          fontSize: "50px",
          padding: "5px",
        }}
      >
        מה בתפריט?
      </h3>
      <div className="option-cnt">
        <OptionTab title="תפריט מעוצב אישית" img={art} />
        <OptionTab title="הסתרת מנה" img={eyeClose} />
        <OptionTab title="עריכת תפריט בלחיצת כפתור" img={edit} />
        <OptionTab title="ללא צורך באפליקצייה" img={appStore} />
      </div>
    </div>
  );
}

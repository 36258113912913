import React from "react";
import "./home.css";
import HomeMenus from "../sections/HomeMenus";
import Footer from "../components/Footer";
import BackgroundImage from "../components/BackgroundImage";
import InMenu from "../components/home/InMenu";
import Manage from "../components/home/Manage";
import Hero from "../components/home/Hero";
import MenuStory from "../components/home/MenuStory";
import MenuContact from "../components/home/MenuContact";
import ContactHome from "../components/home/ContactHome";
import BackgroundCube from "../components/home/BackgroundCube";

export default function Landing() {
  const openWhatsApp = () => {
    const phoneNumber = "+972532266676";

    const message = encodeURIComponent(
      "שלום, אני מתעניין בתפריט דיגיטלי לעסק שלי"
    );

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="home-cnt">
      <button className="wa-btn" onClick={openWhatsApp}></button>

      <BackgroundImage />

      <Hero />

      <InMenu />

      <Manage />

      <HomeMenus />

      <MenuStory />

      <MenuContact />

      <ContactHome />

      <BackgroundCube />

      {/* <Content /> */}

      {/* <Payment />  */}

      <Footer />
    </div>
  );
}

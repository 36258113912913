import axios from "axios";
import {
  USER_GET_MENU_FAIL,
  USER_GET_MENU_REQUEST,
  USER_GET_MENU_SUCCESS,
  USER_POST_MEMBERS_REQUEST,
} from "../constants/userConstant";
import {
  BUSINESS_EDIT_MENU_SUCCESS,
  BUSINESS_POST_MENU_SUCCESS,
} from "../constants/businessConstant";
import {
  BUSINESS_MEMBERS_SUCCESS,
  BUSINESS_STYLE_SUCCESS,
} from "../constants/settingsConstant";

export const getMenu = (name) => async (dispatch) => {
  try {
    dispatch({ type: USER_GET_MENU_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/api/users/menu/${name}`, config);

    dispatch({ type: USER_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_POST_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data.style });
  } catch (error) {
    dispatch({
      type: USER_GET_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMember = (name, member) => async (dispatch) => {
  try {
    dispatch({ type: USER_POST_MEMBERS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `/api/users/members/${name}`,
      { member },
      config
    );

    dispatch({
      type: BUSINESS_MEMBERS_SUCCESS,
      payload: "המשתמש הוסף בהצלחה!",
    });
  } catch (error) {
    dispatch({
      type: USER_GET_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

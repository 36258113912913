import React from "react";
import "./menu2.css";
import { animated, useSpring, easings } from "@react-spring/web";

export default function Menu2({ menu, style }) {
  const { category, meals, pricesTitle } = menu;

  const { font } = style;

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 1200,
    },
  });

  return (
    <animated.div className="menu2-cnt" style={{ ...springs }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {menu.categoryImg ? (
          <>
            <h2 className="menu2-category" style={font}>
              {category}
            </h2>
            <img
              style={{ width: "120px" }}
              src={menu.categoryImg}
              alt={menu.categoryImg}
            />
          </>
        ) : (
          <h2 style={{ width: "100%", ...font }} className="menu2-category">
            {category}
          </h2>
        )}
      </div>
      <div className="menu2-prices-cnt">
        {pricesTitle.map((obj, i) => {
          return (
            <h3 className="menu2-prices-title" key={i} style={font}>
              {obj}
            </h3>
          );
        })}
      </div>
      {meals.map((obj, i) => {
        return obj.available ? (
          <div className="menu2-items-cnt" key={i} style={font}>
            <div>
              <h3 className="menu2-item" style={font}>
                {obj.name}
              </h3>
            </div>
            <div style={{ display: "flex" }}>
              {obj.prices.map((price, index) => {
                return (
                  <h3 className="menu2-price" style={font}>
                    {price}
                  </h3>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        );
      })}
    </animated.div>
  );
}

import React from "react";
import logo from "../uploads/logo3.png";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../actions/businessAction";
import BackgroundImage from "../components/BackgroundImage";
import Footer from "../components/Footer";
import { RWebShare } from "react-web-share";

export default function Trail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contactInfo = {
    firstName: "John",
    lastName: "Doe",
    organization: "Company Inc.",
    phone: "+1234567890",
    email: "john.doe@example.com",
    address: "1234 Main St, Anytown, USA",
  };

  const generateVCard = (contact) => {
    return `BEGIN:VCARD
VERSION:3.0
FN:${contact.firstName} ${contact.lastName}
N:${contact.lastName};${contact.firstName};;;
ORG:${contact.organization}
TEL:${contact.phone}
EMAIL:${contact.email}
ADR:;;${contact.address}
END:VCARD`;
  };

  const downloadVCard = () => {
    const vCardData = generateVCard(contactInfo);
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${contactInfo.firstName}_${contactInfo.lastName}.vcf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="home-cnt">
      <BackgroundImage />
      <div className="home-logo card">
        <img src={logo} alt="logo" style={{ width: "220px" }} />
        <h3 style={{ color: "white" }}>התפריט לעסק שלך</h3>
      </div>
      <div className="cards-cnt">
        <button className="card home-crd" onClick={() => navigate("/login")}>
          <h3>כניסה/רישום לעסק</h3>
        </button>
        <button
          className="card home-crd"
          onClick={() => navigate("/coffee-dizengoff")}
        >
          <h3>לתפריט</h3>
        </button>
        <button className="card home-crd" onClick={() => navigate("/settings")}>
          <h3>הגדרות</h3>
        </button>
        <button className="card home-crd" onClick={() => navigate("/coffee")}>
          <h3>תפריט לא מתוכנת</h3>
        </button>
        <button
          className="card home-crd"
          onClick={() => navigate("/coffee-dizengoff")}
        >
          <h3>תפריט מתוכנת</h3>
        </button>
        <button className="card home-crd" onClick={() => dispatch(logout())}>
          <h3>התנתק</h3>
        </button>
        <div>
          <RWebShare
            data={{
              text: "הינה התפריט:",
              url: "https://www.mealy.co.il",
              title: "Mealy",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <button>Share on Web</button>
          </RWebShare>
        </div>
        <button onClick={downloadVCard}>שמור לאנשי קשר</button>
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import "./MenuButton.css";
import menuImg from "../uploads/menu-img.png";
import { useNavigate, useParams } from "react-router-dom";

function MenuButton() {
  const navigate = useNavigate();
  const param = useParams();

  return (
    <button
      className="menu-button"
      onClick={() => navigate(`/coffee-caramel/menu2`)}
    >
      <img src={menuImg} />
      <h3 className="menu-title">Menu | תפריט</h3>
    </button>
  );
}

export default MenuButton;

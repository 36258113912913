// Accessibility.js

import React, { useState, useEffect } from "react";
import "./accessibility.css";
import svg from "../uploads/accessibility.svg";

export default function Accessibility() {
  const [accOpen, setAccOpen] = useState(false);
  const [isGrayscale, setIsGrayscale] = useState(false);
  const [isHighContrast, setIsHighContrast] = useState(false);
  const [isInvertedColors, setIsInvertedColors] = useState(false);
  const [isLightBackground, setIsLightBackground] = useState(false);
  const [isLinkHighlighted, setIsLinkHighlighted] = useState(false);
  const [isReadableFont, setIsReadableFont] = useState(false);
  const [textSize, setTextSize] = useState(100);
  const [pageZoom, setPageZoom] = useState(1);

  const handleTextSizeIncrease = () => {
    setTextSize((prevSize) => prevSize + 10);

    const handleTextSizeDecrease = () => {
      setTextSize((prevSize) => Math.max(10, prevSize - 10));
    };

    const handleButtonClick = () => {
      setAccOpen(!accOpen);
    };

    const handleGrayScale = () => {
      setIsGrayscale(!isGrayscale);
    };

    const handleHighContrast = () => {
      setIsHighContrast(!isHighContrast);
    };

    const handleInvertColors = () => {
      setIsInvertedColors(!isInvertedColors);
    };

    const handleLightBackground = () => {
      setIsLightBackground(!isLightBackground);
    };

    const handleLinkHighlight = () => {
      setIsLinkHighlighted(!isLinkHighlighted);
    };

    const handleReadableFont = () => {
      setIsReadableFont(!isReadableFont);
    };

    const handleReset = () => {
      setIsGrayscale(false);
      setIsHighContrast(false);
      setIsInvertedColors(false);
      setIsLightBackground(false);
      setIsLinkHighlighted(false);
      setIsReadableFont(false);
    };

    const handleZoomIn = () => {
      setPageZoom((prevZoom) => prevZoom + 0.1);
    };

    const handleZoomOut = () => {
      setPageZoom((prevZoom) => Math.max(0.2, prevZoom - 0.1));
    };

    // useEffect(() => {
    //   document.body.style.filter = isGrayscale ? "grayscale(100%)" : "none";
    // }, [isGrayscale]);

    // useEffect(() => {
    //   document.body.style.filter = isHighContrast
    //     ? "contrast(150%) brightness(80%)"
    //     : "none";
    // }, [isHighContrast]);

    // useEffect(() => {
    //   document.body.style.filter = isInvertedColors ? "invert(100%)" : "none";
    // }, [isInvertedColors]);

    // useEffect(() => {
    //   document.body.style.background = isLightBackground ? "#ffffff" : "none";
    // }, [isLightBackground]);

    // useEffect(() => {
    //   const links = document.querySelectorAll("a");
    //   links.forEach((link) => {
    //     link.style.background = isLinkHighlighted ? "#ffff99" : "none";
    //   });
    // }, [isLinkHighlighted]);

    // useEffect(() => {
    //   document.body.style.fontFamily = isReadableFont
    //     ? "Arial, sans-serif"
    //     : "";
    // }, [isReadableFont]);

    // useEffect(() => {
    //   document.body.style.fontSize = `${textSize}%`;
    // }, [textSize]);

    // useEffect(() => {
    //   document.body.style.zoom = pageZoom;
    // }, [pageZoom]);

    return (
      <>
        <button
          onClick={handleButtonClick}
          className={`acc-btn ${accOpen ? "open" : ""}`}
        >
          <img
            src={svg}
            alt="Accessibility Icon"
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
        </button>
        <div className={`acc-cnt ${accOpen ? "open" : ""}`}>
          <button onClick={handleZoomIn}>הגדלה</button>
          <button onClick={handleZoomOut}>הקטנה</button>
          <button onClick={handleGrayScale}>גווני אפור</button>
          <button onClick={handleHighContrast}>ניגודיות גבוהה</button>
          <button onClick={handleInvertColors}>ניגודיות הפוכה</button>
          <button onClick={handleLightBackground}>רקע בהיר</button>
          <button onClick={handleLinkHighlight}>הדגשת קישורים</button>
          <button onClick={handleReadableFont}>פונט קריא</button>
          <button onClick={handleReset}>איפוס</button>
        </div>
      </>
    );
  };
}

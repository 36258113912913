import React from "react";
import phone from "../../uploads/phone.png";

export default function Manage() {
  return (
    <div className="manage-cnt">
      <h3 className="manage-title">
        ניהול נוח של תפריט המסעדה <br /> הישר מהנייד שלך
      </h3>
      <img src={phone} alt={"phone"} />
    </div>
  );
}

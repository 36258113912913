import React from "react";
import Stories from "react-insta-stories";
import story1 from "../../uploads/home-story1.png";
import story2 from "../../uploads/home-story2.png";
import story3 from "../../uploads/home-story3.png";
import story4 from "../../uploads/home-story4.png";
import story5 from "../../uploads/home-story5.png";
import story6 from "../../uploads/home-story6.png";

export default function App() {
  let arr = [story1, story2, story3, story4, story5, story6];
  return (
    <div className="menu-story-cnt">
      <h3 style={{ textAlign: "center", marginTop: "20px" }}>
        תוציאו מהראש את המילה תפריט <br />
        ותירשמו שטח פרסום
      </h3>
      <h3 className="gradient-text pulset">סטורי בתפריט</h3>
      <div>
        <Stories
          stories={arr}
          defaultInterval={2500}
          width={250}
          height={400}
          loop
        />
      </div>
      <h3 style={{ textAlign: "center", marginTop: "20px" }}>
        בעזרת הסטורי תוכלו לפרסם לקהל שלכם אירועים מיוחדים מבצעים ועוד...
      </h3>
      <span style={{ textAlign: "center", margin: " 20px 0" }}>
        שדרוג ענק לעסק שלך!
      </span>
    </div>
  );
}

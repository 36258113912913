import React from "react";
import logo from "../uploads/logo3.png";
import { animated, useSpring, easings } from "@react-spring/web";
import margoza from "../uploads/margoza-menu.png";
import minus1 from "../uploads/minus1-menu.png";
import dizengoff from "../uploads/dizengoff-menu.png";
import { useNavigate } from "react-router-dom";

export default function HomeMenus() {
  const navigate = useNavigate();

  return (
    <>
      <h3 style={{ color: "white", marginTop: "80px" }}>
        עם עיצוב שלנו התפריט שלכם ירחף
      </h3>
      <span style={{ color: "white", marginTop: "0px" }}>
        ניתן ללחוץ על התפריטים
      </span>
      <div className="menus-cnt">
        <button
          onClick={() => navigate("/margoza/menu")}
          className="home-menu menu1"
          style={{ backgroundImage: `url(${margoza})` }}
        ></button>
        <button
          onClick={() => navigate("/minus1/menu")}
          className="home-menu menu3"
          style={{ backgroundImage: `url(${minus1})` }}
        ></button>
        <button
          onClick={() => navigate("/coffee-dizengoff/menu")}
          className="home-menu menu2"
          style={{ backgroundImage: `url(${dizengoff})` }}
        ></button>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./menu.css";
import logo from "../uploads/diz.png";
import Menu1 from "../components/menu/Menu1";
import Menu2 from "../components/menu/Menu2";
import Menu3 from "../components/menu/Menu3";
import Menu4 from "../components/menu/Menu4";
import BackgroundImage from "../components/BackgroundImage";
import whiteLeather from "../uploads/white-leather.jpg";
import BusinessLogo from "../components/BusinessLogo";
import Footer from "../components/Footer";
import MenuButtons from "../components/MenuButtons";

export default function CoffeeDizengoff() {
  const menu = [
    {
      type: 1,
      category: "מהטאבון",
      categoryImg:
        "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/pizza.png?alt=media&token=e549014f-49bf-4c69-8c93-e5e52fc0e4a9",
      meals: [
        {
          name: "פיצת הבית",
          desc: 'תוספת אחת עלינו השאר בתוספת 5 ש"ח',
          price: 49,
          note: "מכיל גלוטן",
        },
        {
          name: "פוקצ'ה אנטיפסטי",
          desc: "פוקצ'ת הבית עם ירקות לבחירה",
          price: 42,
          note: "מכיל גלוטן",
        },
        {
          name: "בייגל טוסט",
          desc: "גבינה צהובה מגורדת עם רוטב פיצה בבייגל קריספי",
          price: 45,
          note: "מכיל גלוטן",
        },
        {
          name: "לחם שום",
          desc: "לחם קריספי מוגש עם שום קונפי מעליו",
          price: 40,
          note: "מכיל גלוטן",
        },
      ],
    },
    {
      type: 1,
      categoryImg:
        "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/salad.png?alt=media&token=88b112ef-dd43-46b5-9c77-8f404c9d9829",
      category: "סלטים",
      meals: [
        {
          name: "סלט יווני",
          desc: "עגבניות, מלפפונים, עלי רוקט, עלי חסה, בולגרית בטיבול מיץ לימון שמן זית",
          price: 45,
        },
        {
          name: "סלט ישראלי",
          desc: "עגבניות מלפפונים בצל סגול פטרוזיליה לימון שמן זית לצד טחינה",
          price: 45,
        },
        {
          name: "סלט בוראטה",
          desc: "גבינת בוראטה עגבניות שרי עלי רוקט בזיליקום בתוספת קרוטונים ",
          price: 45,
        },
        {
          name: "סלט טונה",
          desc: "עגבניות חסה מלפפון חמוץ תירס בצל פטרוזיליה טונה וביצה קשה",
          price: 45,
        },
      ],
    },
    {
      type: 2,
      category: "ALCOHOL",
      pricesTitle: ["צ'ייסר", "כוס", "בקבוק"],
      meals: [
        {
          name: "ערק עלית",
          prices: ["15", "30", "260"],
          available: 1,
        },
        {
          name: "קמפרי",
          prices: ["15", "30", ""],
          available: 1,
        },
        {
          name: "גי'ן ",
          prices: ["15", "35", ""],
          available: 1,
        },
        {
          name: "וויסקי בלאק לייבל",
          prices: ["20", "35", "450"],
          available: 1,
        },
        {
          name: "בלוגה",
          prices: ["20", "35", "450"],
          available: 1,
        },
        {
          name: "פינלנדיה",
          prices: ["15", "30", "300"],
          available: 1,
        },
        {
          name: "טקילה קוארבו",
          prices: ["20", "35", ""],
          available: 1,
        },
        {
          name: "ואן-גוך",
          prices: ["18", "35", "450"],
          available: 1,
        },
        {
          name: "רום קפטן-מורגן",
          prices: ["18", "35", ""],
          available: 1,
        },
      ],
    },
    {
      type: 3,
      category: "COCKTAILS",
      meals: [
        {
          available: 1,
          name: "LIMONARAK",
          desc: "לימונענע גרוס על בסיס ערק",
          price: 35,
          img: "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/limonarak.png?alt=media&token=664c5644-0a73-409d-9874-6b2f35c0cff0",
        },
        {
          available: 1,
          name: "VIOLATE",
          desc: "קוקטייל בטעם סיגליות על בסיס וודקה",
          price: 40,
          img: "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/violette.png?alt=media&token=296eae13-19ca-4602-aad1-256c3b42fc85",
        },
        {
          available: 1,
          name: "CRAZY-PINEAPPLE",
          desc: "טעמים אקזוטים של אננס ופירות טרופיים על בסיס טקילה",
          price: 44,
          img: "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/limonarak.png?alt=media&token=664c5644-0a73-409d-9874-6b2f35c0cff0",
        },
        {
          available: 1,
          name: "LIMONARAK",
          desc: "לימונענע גרוס על בסיס ערק",
          price: 43,
          img: "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/limonarak.png?alt=media&token=664c5644-0a73-409d-9874-6b2f35c0cff0",
        },
      ],
    },
    {
      type: 4,
      category: "נשנושים",
      titles: ["כל הנשנושים ב-₪30", "4 נשנושים ב-₪100"],
      img: {
        src: "https://firebasestorage.googleapis.com/v0/b/here-i-am7.appspot.com/o/fries.png?alt=media&token=b66c699e-0b97-4e7a-b36b-38aef27b6aac",
        alt: "fries",
        style: { left: "40px", opacity: "55%", marginTop: "40px" },
      },
      meals: [
        {
          name: "צ'יפס",
        },
        {
          name: "צ'יפס בטטה",
        },
        {
          name: "אדממה",
        },
        {
          name: "טבעות בצל",
        },
        {
          name: "כרובית מטוגנת",
        },
      ],
    },
  ];

  const [time, setTime] = useState(0);
  const [interval, setIntervl] = useState(2000);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    menu.map((obj) => {
      setCategories((prev) => {
        prev.push(obj.category);
        return prev;
      });
    });
  }, [menu]);
  return (
    <div style={style.page}>
      <BackgroundImage img={whiteLeather} />
      <BusinessLogo img={logo} />
      <MenuButtons categories={categories} />
      <div style={style.menu}>
        {menu.map((obj, i) => {
          switch (obj.type) {
            case 1:
              return <Menu1 menu={menu[i]} style={style} />;
            case 2:
              return <Menu2 menu={menu[i]} style={style} />;
            case 3:
              return <Menu3 menu={menu[i]} style={style} />;
            case 4:
              return <Menu4 menu={menu[i]} style={style} />;
            default:
              return "";
          }
        })}
      </div>
      <Footer />
    </div>
  );
}

const style = {
  page: {
    backgroundColor: "transperent",
    width: "100%",
    minHeight: "100vh",
  },
  logo: {
    width: "100%",
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
  },
  font: {
    color: "#2e2e2e",
  },
  category: {},
};

import React from "react";

export default function BusinessLogo({ img }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {img ? <img src={img} style={{ width: " 70%" }} alt="logo" /> : ""}
    </div>
  );
}

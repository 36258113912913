import {
  USER_GET_MENU_FAIL,
  USER_GET_MENU_REQUEST,
  USER_GET_MENU_SUCCESS,
  USER_LOGOUT,
} from "../constants/userConstant";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_GET_MENU_REQUEST:
      return { loading: true };
    case USER_GET_MENU_SUCCESS:
      return { loading: false, menu: action.payload };
    case USER_GET_MENU_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

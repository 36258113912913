import React from "react";
import "./alma.css";
import img1 from "../../uploads/alma-1.jpg";
import img2 from "../../uploads/alma-2.jpg";
import img3 from "../../uploads/alma-3.jpg";
import img4 from "../../uploads/alma-4.jpg";
import { useNavigate } from "react-router-dom";

export default function Alma() {
  const navigate = useNavigate();

  return (
    <div className="alma-cnt">
      <h2 className="title-alma">
        ברוכים הבאים <br />
        למלון עלמה
      </h2>
      <div className="image-container">
        <img src={img1} />
        <img src={img2} />
        <img src={img3} />
        <img src={img4} />
      </div>
      <button
        style={{ height: "40px" }}
        onClick={() => navigate("/alma/phone")}
      >
        להזמנת חדר לחץ כאן
      </button>
      <button
        style={{ height: "40px", backgroundColor: "#1c4a71" }}
        onClick={() => (window.location.href = "tel:099736000")}
      >
        לחץ לשירות לקוחות
      </button>
    </div>
  );
}

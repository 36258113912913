import React, { useEffect, useState } from "react";
import EditMenuSettings from "../components/EditMenuSettings";
import CategorySettings from "../components/CategorySettings";
import StyleSettings from "../components/StyleSettings";
import ChangeCategoryPlace from "../components/ChangeCategoryPlace";
import CreateNewMenu from "../components/CreateNewMenu";
import ManageSchdule from "../components/ManageSchdule";
import GeneralSettings from "../components/GeneralSettings";
import { useDispatch, useSelector } from "react-redux";
import { businessInfo } from "../actions/businessAction";
import { useNavigate } from "react-router-dom";
import BannerSettings from "../components/BannerSettings";
import Members from "../components/Members";

export default function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { info } = useSelector((state) => state.business);

  const [type, setType] = useState("EditMenuSettings");

  useEffect(() => {
    dispatch(businessInfo());
  }, []);

  const renderActiveComponent = () => {
    switch (type) {
      case "CategorySettings":
        return <CategorySettings />;
      case "EditMenuSettings":
        return <EditMenuSettings />;
      case "ChangeCategoryPlace":
        return <ChangeCategoryPlace />;
      case "StyleSettings":
        return <StyleSettings />;
      case "CreateNewMenu":
        return <CreateNewMenu />;
      case "Members":
        return <Members />;
      case "ManageSchdule":
        return <ManageSchdule />;
      case "BannerSettings":
        return <BannerSettings />;
      case "GeneralSettings":
        return <GeneralSettings />;
      default:
        return <EditMenuSettings />;
    }
  };
  return (
    <div>
      <h2>הגדרות</h2>
      <div className="settings-btn-cnt">
        <button
          className="settings-btn"
          onClick={() => setType("EditMenuSettings")}
        >
          עריכת תפריט
        </button>
        <button
          className="settings-btn"
          onClick={() => setType("CategorySettings")}
        >
          קטגוריה חדשה
        </button>
        <button
          className="settings-btn"
          onClick={() => setType("ChangeCategoryPlace")}
        >
          מיקום/הסתרת קטגוריה
        </button>
        <button className="settings-btn" onClick={() => setType("Members")}>
          חברי מועדון
        </button>
        <button
          className="settings-btn"
          onClick={() => setType("StyleSettings")}
        >
          עיצוב תפריט
        </button>
        <button
          className="settings-btn"
          onClick={() => setType("BannerSettings")}
        >
          באנר
        </button>
        <button
          className="settings-btn"
          onClick={() => navigate(`/${info.name}/menu`)}
        >
          לתפריט
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {renderActiveComponent()}
      </div>
    </div>
  );
}

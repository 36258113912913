import React from "react";
import blueVelvet from "../uploads/dark-wood.jpg";
import blackWood from "../uploads/black-wood.png";
import "./background.css";

export default function BackgroundImage({ img }) {
  return (
    <img
      src={img ? img : blackWood}
      alt={"background"}
      className="background"
    />
  );
}

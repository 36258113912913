import React, { useState } from "react";
import "./category-settings.css";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../actions/businessAction";
import { BsXCircleFill } from "react-icons/bs";
import Loader from "./Loader";

export default function CategorySettings() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.menu);

  const menuTypes = [
    "רגיל",
    "מרובה מחירים",
    "עם תמונה",
    "קטגוריה ראשית ומשניות",
  ];

  const [category, setCategory] = useState("");
  const [mealObj, setMealObj] = useState({
    name: "",
    desc: "",
    price: "",
    note: "",
    prices: ["", "", ""],
    available: 1,
    img: "",
  });
  const [meals, setMeals] = useState([mealObj]);
  const [type, setType] = useState("1");
  const [select, setSelect] = useState(0);
  const [pricesTitle, setPricesTitle] = useState(["קטן", "בנוני", "גדול"]);

  const [categoryImg, setCategoryImg] = useState("");

  const handleMealChange = (field, value, index) => {
    setMeals((prevMeals) => {
      const updatedMeals = [...prevMeals];
      updatedMeals[index] = { ...updatedMeals[index], [field]: value };
      return updatedMeals;
    });
  };
  const [prices, setPrices] = useState(["", "", ""]);

  const handleDeleteMeals = (index) => {
    setMeals((prevMeals) => {
      const updatedMeals = prevMeals.filter((_, i) => i !== index);
      return updatedMeals;
    });
  };

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setType(selectedType);
    switch (selectedType) {
      case "1":
        setMealObj({ name: "", desc: "", price: 0, note: "", available: 1 });
        setMeals([{ name: "", desc: "", price: 0, note: "", available: 1 }]);
        break;

      case "2":
        setMealObj({
          name: "",
          prices: ["", "", ""],
          pricesTitle,
          available: 1,
        });
        setMeals([
          { name: "", prices: ["", "", ""], pricesTitle, available: 1 },
        ]);
        break;

      case "3":
        break;

      case "4":
        break;

      default:
        break;
    }
  };

  const handleCategoryChange = (e) => {
    setSelect(1);
    setCategory(e.target.value);
    if (e.target.value == "") {
      setSelect(0);
    }
  };

  const handlePopTitle = () => {
    setPricesTitle(pricesTitle.slice(0, -1));
    setMealObj({ ...mealObj, prices: prices.slice(0, -1) });
    const updatedMeals = meals.map((meal) => {
      if (Array.isArray(meal.prices) && meal.prices.length > 0) {
        const updatedPrices = meal.prices.slice(0, -1);

        return {
          ...meal,
          prices: updatedPrices,
        };
      }

      return meal;
    });

    setMeals(updatedMeals);
  };

  const addPriceTitle = () => {
    let add = "גדול";
    if (pricesTitle.length == 0) {
      add = "קטן";
    }
    if (pricesTitle.length == 1) {
      add = "בנוני";
    }
    setPricesTitle([...pricesTitle, add]);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="setting-card">
      <h2 style={{ marginBottom: "10px" }}>הזן שם לתפריט</h2>
      <input style={{ marginBottom: "10px" }} placeholder="הזן כאן" />
      <h2 style={{ marginBottom: "15px" }}>הוספת קטגוריה/מנות</h2>
      <h3 className="title">בחר סוג תפריט:</h3>
      <select onChange={(e) => handleTypeChange(e)} disabled={select}>
        {menuTypes.map((type, i) => {
          return <option value={i + 1}>{type}</option>;
        })}
      </select>
      <div className="new-menu-ctn">
        <input
          style={{ textAlign: "right", width: "80%" }}
          placeholder="הזן כאן קטגוריה ראשית"
          onChange={(e) => handleCategoryChange(e)}
        />
        {type == 2 ? (
          <div
            className="new-menu2-prices"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ fontSize: "18px" }}>כמות עמודות:</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
                padding: "5px 0",
              }}
            >
              <button
                style={{
                  width: "30px",
                  marginRight: "10px",
                  backgroundColor: "rgb(117, 255, 16)",
                }}
                onClick={() => addPriceTitle()}
              >
                +
              </button>
              <button
                style={{
                  width: "30px",
                  marginLeft: "10px",
                  backgroundColor: "red",
                }}
                onClick={handlePopTitle}
              >
                -
              </button>
              <div>
                {pricesTitle.map((title, i) => {
                  return (
                    <input
                      placeholder={title}
                      style={{ width: "50px" }}
                      onChange={(e) =>
                        setPricesTitle((prevTitle) => {
                          prevTitle[i] = e.target.value;
                          return prevTitle;
                        })
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {meals.map((obj, i) => {
          switch (type) {
            case "1":
              return (
                <div key={i} className="new-menu1">
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2>הוספת מנה</h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "200px",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      placeholder="הזן שם מנה"
                      onChange={(e) =>
                        handleMealChange("name", e.target.value, i)
                      }
                      value={obj.name}
                    />
                    <input
                      placeholder="הזן תיאור מנה (לא חובה)"
                      onChange={(e) =>
                        handleMealChange("desc", e.target.value, i)
                      }
                    />
                    <input
                      onChange={(e) =>
                        handleMealChange("note", e.target.value, i)
                      }
                      placeholder="הזן הערה (לא חובה)"
                    />
                    <input
                      placeholder="הזן מחיר"
                      onChange={(e) =>
                        handleMealChange("price", e.target.value, i)
                      }
                    />
                  </div>
                </div>
              );
            case "2":
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <input
                    placeholder="שם המנה"
                    style={{ width: "120px" }}
                    onChange={(e) =>
                      handleMealChange("name", e.target.value, i)
                    }
                  />
                  <div>
                    {pricesTitle.map((title, index) => {
                      return (
                        <input
                          placeholder="מחיר"
                          style={{ width: "50px" }}
                          onChange={(e) =>
                            setMeals((prevMeals) => {
                              prevMeals[i].prices[index] = e.target.value;
                              return prevMeals;
                            })
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              );
            case "3":
              return (
                <div key={i} className="new-menu1">
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2>הוספת מנה</h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "200px",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      placeholder="הזן שם מנה"
                      onChange={(e) =>
                        handleMealChange("name", e.target.value, i)
                      }
                      value={obj.name}
                    />
                    <input
                      placeholder="הזן תיאור מנה (לא חובה)"
                      onChange={(e) =>
                        handleMealChange("desc", e.target.value, i)
                      }
                    />
                    <input
                      onChange={(e) =>
                        handleMealChange("note", e.target.value, i)
                      }
                      placeholder="הזן הערה (לא חובה)"
                    />
                    <input
                      placeholder="הוסף קישור לתמונה"
                      onChange={(e) =>
                        handleMealChange("img", e.target.value, i)
                      }
                    />
                    <input
                      placeholder="הזן מחיר"
                      onChange={(e) =>
                        handleMealChange("price", e.target.value, i)
                      }
                    />
                  </div>
                </div>
              );
            case "4":
              return (
                <div key={i} className="new-menu1">
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2>הוספת מנה</h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "200px",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      placeholder="הזן שם מנה"
                      onChange={(e) =>
                        handleMealChange("name", e.target.value, i)
                      }
                      value={obj.name}
                    />
                    <input
                      placeholder="הזן תיאור מנה (לא חובה)"
                      onChange={(e) =>
                        handleMealChange("desc", e.target.value, i)
                      }
                    />
                    <input
                      onChange={(e) =>
                        handleMealChange("note", e.target.value, i)
                      }
                      placeholder="הזן הערה (לא חובה)"
                    />
                    <input
                      placeholder="הזן מחיר"
                      onChange={(e) =>
                        handleMealChange("price", e.target.value, i)
                      }
                    />
                  </div>
                </div>
              );
            default:
              return <h1>...</h1>;
          }
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            height: "40px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "#007eff",
              width: "120px",
              alignSelf: "center",
            }}
            onClick={() =>
              dispatch(
                addCategory({
                  category,
                  meals,
                  type,
                  categoryImg,
                  pricesTitle,
                })
              )
            }
          >
            אישור
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./menu.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import logo from "../uploads/diz.png";
import whiteLeather from "../uploads/white-leather.jpeg";

import Menu1 from "../components/menu/Menu1";
import Menu2 from "../components/menu/Menu2";
import Menu3 from "../components/menu/Menu3";
import Menu4 from "../components/menu/Menu4";
import { getMenu } from "../actions/userActions";
import BusinessLogo from "../components/BusinessLogo";
import BackgroundImage from "../components/BackgroundImage";
import Loader from "../components/Loader";
import Stories from "react-insta-stories";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

export default function Menu() {
  const { name } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { menu } = user;

  const [filteredMenu, setFilteredMenu] = useState([]);

  const style = useSelector((state) => state.style);
  const { background, logo, withButtons: withButton, banner } = style || {};

  const [index, setIndex] = useState(0);
  const [withButtons, setWithButtons] = useState(withButton);

  useEffect(() => {
    // Dispatch action to get menu
    dispatch(getMenu(name));
  }, [dispatch, name]); // Dependency array includes dispatch and name

  // Filter the categoris that not available
  useEffect(() => {
    if (menu && menu.length > 0) {
      // Filter the menu and set the new menu
      setFilteredMenu(menu.filter((obj) => obj.available !== false));
    }
  }, [menu]);

  const handleButtonClick = (i) => {
    setIndex(i);
  };

  if (!user.menu && filteredMenu) {
    return <Loader />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: background.url,
        minHeight: "100vh",
      }}
    >
      {background.type == "image" ? (
        <BackgroundImage img={background.url} />
      ) : (
        ""
      )}

      <BusinessLogo img={logo.url} />

      <div style={{ placeSelf: "center" }}>
        {banner.stories ? (
          <Stories
            stories={banner.stories}
            defaultInterval={2500}
            width={250}
            height={400}
            loop
          />
        ) : (
          ""
        )}
      </div>

      {withButtons ? (
        <div className="menu-btn-cnt">
          {menu
            .filter((obj) => obj.available !== false)
            .map((obj, i) => (
              <button
                onClick={() => handleButtonClick(i)}
                style={{ width: "120px" }}
                key={i}
              >
                {obj.category}
              </button>
            ))}
        </div>
      ) : null}

      <div style={{ padding: "0 30px" }}>
        {filteredMenu.map((obj, i) => {
          if (!withButtons || i === index) {
            switch (obj.type) {
              case "1":
                return <Menu1 menu={obj} style={style} key={i} />;
              case "2":
                return <Menu2 menu={obj} style={style} key={i} />;
              case "3":
                return <Menu3 menu={obj} style={style} key={i} />;
              case "4":
                return <Menu4 menu={obj} style={style} key={i} />;
              default:
                return "";
            }
          }
          return null;
        })}
      </div>
      <Footer color="black" />
    </div>
  );
}

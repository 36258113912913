import {
  BUSINESS_LOGIN_REQUEST,
  BUSINESS_LOGIN_SUCCESS,
  BUSINESS_LOGIN_FAIL,
  BUSINESS_LOGOUT,
  BUSINESS_REGISTER_REQUEST,
  BUSINESS_REGISTER_SUCCESS,
  BUSINESS_REGISTER_FAIL,
  BUSINESS_ADD_CATEGORY_SUCCESS,
  BUSINESS_ADD_CATEGORY_REQUEST,
  BUSINESS_ADD_CATEGORY_FAIL,
  BUSINESS_ADD_MEAL_REQUEST,
  BUSINESS_ADD_MEAL_SUCCESS,
  BUSINESS_GET_MENU_SUCCESS,
  BUSINESS_ADD_MEAL_FAIL,
  BUSINESS_EDIT_MENU_REQUEST,
  BUSINESS_EDIT_MENU_SUCCESS,
  BUSINESS_EDIT_MENU_FAIL,
  BUSINESS_POST_MENU_REQUEST,
  BUSINESS_POST_MENU_SUCCESS,
  BUSINESS_POST_MENU_FAIL,
} from "../constants/businessConstant";
import {
  BUSINESS_GS_EDIT_FAIL,
  BUSINESS_GS_EDIT_REQUEST,
  BUSINESS_GS_EDIT_SUCCESS,
  BUSINESS_MEMBERS_FAIL,
  BUSINESS_MEMBERS_REQUEST,
  BUSINESS_MEMBERS_SUCCESS,
  BUSINESS_STYLE_FAIL,
  BUSINESS_STYLE_REQUEST,
  BUSINESS_STYLE_SUCCESS,
} from "../constants/settingsConstant";
import {
  USER_GET_MENU_REQUEST,
  USER_POST_MEMBERS_REQUEST,
} from "../constants/userConstant";

export const businessRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case BUSINESS_REGISTER_REQUEST:
      return { loading: true };
    case BUSINESS_REGISTER_SUCCESS:
      return { loading: false, BusinessInfo: action.payload };
    case BUSINESS_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const businessReducer = (state = {}, action) => {
  switch (action.type) {
    case BUSINESS_LOGIN_REQUEST:
      return { loading: true };
    case BUSINESS_GS_EDIT_REQUEST:
      return { loading: true };
    case BUSINESS_LOGIN_SUCCESS:
      return { loading: false, info: action.payload };
    case BUSINESS_GS_EDIT_SUCCESS:
      return { loading: false, info: action.payload };
    case BUSINESS_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_GS_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const menuReducer = (state = { menu: {} }, action) => {
  switch (action.type) {
    case BUSINESS_POST_MENU_REQUEST:
      return { loading: true };
    case BUSINESS_POST_MENU_SUCCESS:
      return action.payload;
    case BUSINESS_POST_MENU_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_GET_MENU_SUCCESS:
      return action.payload;
    case BUSINESS_ADD_CATEGORY_REQUEST:
      return { loading: true };
    case BUSINESS_ADD_MEAL_REQUEST:
      return { loading: true };
    case BUSINESS_ADD_CATEGORY_SUCCESS:
      return action.payload;
    case BUSINESS_ADD_MEAL_SUCCESS:
      return action.payload;
    case BUSINESS_ADD_MEAL_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_ADD_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_EDIT_MENU_REQUEST:
      return { loading: true, ...state };
    case BUSINESS_EDIT_MENU_SUCCESS:
      return action.payload;
    case BUSINESS_EDIT_MENU_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case BUSINESS_ADD_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const styleReducer = (state = {}, action) => {
  switch (action.type) {
    case BUSINESS_STYLE_REQUEST:
      return {
        loading: true,
        background: { type: "color", url: "" },
        font: { color: "black", fontFamily: "system-ui" },
        logo: { url: "", style: "" },
      };
    case BUSINESS_STYLE_SUCCESS:
      return action.payload;
    case BUSINESS_STYLE_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const membersReducer = (state = {}, action) => {
  switch (action.type) {
    case BUSINESS_MEMBERS_REQUEST:
      return {
        loading: true,
      };
    case USER_POST_MEMBERS_REQUEST:
      return {
        loading: true,
      };
    case BUSINESS_MEMBERS_SUCCESS:
      return action.payload;
    case BUSINESS_MEMBERS_FAIL:
      return { loading: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};

import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";

export default function ManageSchdule() {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  return (
    <div className="setting-card">
      <h2>ניהול זמני תפריטים</h2>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          margin: "10px 0",
          padding: "5px",
        }}
      >
        {[1, 2, 3, 4, 5, 6, 26].map((index) => (
          <button
            key={index}
            style={{
              backgroundColor: activeButton === index ? "gray" : "black",
              width: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => handleButtonClick(index)}
          >
            {String.fromCharCode(1487 + index)}
          </button>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          alignItems: "center",
        }}
      >
        <input type={"time"} style={{ width: "80px" }} />
        <FaArrowLeft />
        <input type={"time"} style={{ width: "80px" }} />
        <select>
          <option>בוקר</option>
        </select>
      </div>
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-around",
          width: "80%",
          marginTop: "10px",
        }}
      >
        <button>+</button>
        <button>אישור</button>
      </div>
    </div>
  );
}

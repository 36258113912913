import React from "react";
import "./option-tab.css";

export default function OptionTab({ img, title, width = false }) {
  return (
    <div className={width ? "big-expert-card" : "expert-card"}>
      <img className="expert-img" src={img} alt={img} />
      <strong style={{ color: "black" }} className="card-title">
        {title}
      </strong>
    </div>
  );
}

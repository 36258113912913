import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./reducers/userReducers.js";
import {
  businessReducer,
  businessRegisterReducer,
  membersReducer,
  menuReducer,
  settingsReducer,
  styleReducer,
} from "./reducers/businessReducer.js";

const reducer = combineReducers({
  user: userReducer,
  business: businessReducer,
  businessRegister: businessRegisterReducer,
  menu: menuReducer,
  settings: settingsReducer,
  style: styleReducer,
  members: membersReducer,
});
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const businessFromStorage = localStorage.getItem("businessInfo")
  ? JSON.parse(localStorage.getItem("businessInfo"))
  : null;

const menuFromStorage = localStorage.getItem("menu")
  ? JSON.parse(localStorage.getItem("menu"))
  : null;

const styleFromStorage = localStorage.getItem("style")
  ? JSON.parse(localStorage.getItem("style"))
  : null;

const membersFromStorage = localStorage.getItem("members")
  ? JSON.parse(localStorage.getItem("members"))
  : { loading: false };

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  business: { info: businessFromStorage },
  menu: menuFromStorage,
  style: styleFromStorage,
  members: membersFromStorage,
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

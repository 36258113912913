export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_GET_MENU_REQUEST = "USER_GET_MENU_REQUEST";
export const USER_GET_MENU_SUCCESS = "USER_GET_MENU_SUCCESS";
export const USER_GET_MENU_FAIL = "USER_GET_MENU_FAIL";

export const USER_POST_MEMBERS_REQUEST = "USER_POST_MEMBERS_REQUEST";

import React from "react";
import "./HomeButton.css";

const HomeButton = ({ icon: Icon, name, href, tel }) => {
  const handleClick = () => {
    if (href) {
      window.open(href, "_blank");
    } else if (tel) {
      window.open(`tel:${tel}`);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleClick}
    >
      <button className="home-button">
        <Icon style={{ height: "65%", width: "65%" }} />
      </button>
      <srtong style={{ color: "white", fontWeight: "700", marginTop: "8px" }}>
        {name}
      </srtong>
    </div>
  );
};

export default HomeButton;

import axios from "axios";
import {
  BUSINESS_LOGIN_REQUEST,
  BUSINESS_LOGIN_SUCCESS,
  BUSINESS_LOGIN_FAIL,
  BUSINESS_REGISTER_REQUEST,
  BUSINESS_REGISTER_SUCCESS,
  BUSINESS_REGISTER_FAIL,
  BUSINESS_ADD_CATEGORY_REQUEST,
  BUSINESS_ADD_CATEGORY_SUCCESS,
  BUSINESS_ADD_CATEGORY_FAIL,
  BUSINESS_ADD_MEAL_REQUEST,
  BUSINESS_ADD_MEAL_SUCCESS,
  BUSINESS_ADD_MEAL_FAIL,
  BUSINESS_LOGOUT,
  BUSINESS_GET_MENU_SUCCESS,
  BUSINESS_EDIT_MENU_REQUEST,
  BUSINESS_EDIT_MENU_SUCCESS,
  BUSINESS_EDIT_MENU_FAIL,
  BUSINESS_POST_MENU_REQUEST,
  BUSINESS_POST_MENU_SUCCESS,
  BUSINESS_POST_MENU_FAIL,
} from "../constants/businessConstant";
import {
  BUSINESS_MEMBERS_SUCCESS,
  BUSINESS_STYLE_SUCCESS,
} from "../constants/settingsConstant";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: BUSINESS_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/business/auth",
      { email, password },
      config
    );

    dispatch({ type: BUSINESS_LOGIN_SUCCESS, payload: data.auth });
    dispatch({ type: BUSINESS_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_MEMBERS_SUCCESS, payload: data.members });

    localStorage.setItem("businessInfo", JSON.stringify(data.auth));
    localStorage.setItem("menu", JSON.stringify(data.menu));
    localStorage.setItem("members", JSON.stringify(data.members));
  } catch (error) {
    dispatch({
      type: BUSINESS_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addCategory = (obj) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_ADD_CATEGORY_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post("/api/business/category", obj, config);

    localStorage.setItem("menu", JSON.stringify(data));
    dispatch({ type: BUSINESS_ADD_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_ADD_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMeal = (meal) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_ADD_MEAL_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post("/api/business/meal", { meal }, config);

    localStorage.setItem("menu", JSON.stringify(data));
    dispatch({ type: BUSINESS_ADD_MEAL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_ADD_MEAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerBusiness = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: BUSINESS_REGISTER_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/business/",
      { email, password },
      config
    );

    localStorage.setItem("businessInfo", JSON.stringify(data));
    dispatch({ type: BUSINESS_REGISTER_SUCCESS, payload: data });
    dispatch({ type: BUSINESS_LOGIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("businessInfo");
  localStorage.removeItem("menu");

  dispatch({ type: BUSINESS_LOGOUT });
};

export const businessInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_ADD_CATEGORY_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.get("/api/business", config);

    localStorage.setItem("menu", JSON.stringify(data.menu));
    localStorage.setItem("style", JSON.stringify(data.style));
    localStorage.setItem("members", JSON.stringify(data.members));

    dispatch({ type: BUSINESS_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data.style });
    dispatch({ type: BUSINESS_MEMBERS_SUCCESS, payload: data.members });
  } catch (error) {
    dispatch({
      type: BUSINESS_ADD_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editMenuAction = (menu) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_EDIT_MENU_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.put("/api/business/menu", { menu }, config);

    localStorage.setItem("menu", JSON.stringify(data));
    dispatch({ type: BUSINESS_EDIT_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_EDIT_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postMenuAction = (menu) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_POST_MENU_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post("/api/business/menu", { menu }, config);

    dispatch({ type: BUSINESS_POST_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_POST_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

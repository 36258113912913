import React from "react";

export default function CreateNewMenu() {
  return (
    <div className="setting-card">
      <h2 style={mb}>הזן שם תפריט</h2>
      <input style={mb} placeholder="הזן כאן" />
      <button>אישור</button>
    </div>
  );
}

const mb = {
  marginBottom: "15px",
};

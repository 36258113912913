export const BUSINESS_GS_EDIT_REQUEST = "BUSINESS_GS_EDIT_REQUEST";
export const BUSINESS_GS_EDIT_SUCCESS = "BUSINESS_GS_EDIT_SUCCESS";
export const BUSINESS_GS_EDIT_FAIL = "BUSINESS_GS_EDIT_FAIL";

export const BUSINESS_STYLE_REQUEST = "BUSINESS_STYLE_REQUEST";
export const BUSINESS_STYLE_SUCCESS = "BUSINESS_STYLE_SUCCESS";
export const BUSINESS_STYLE_FAIL = "BUSINESS_STYLE_FAIL";

export const BUSINESS_MEMBERS_REQUEST = "BUSINESS_MEMBERS_REQUEST";
export const BUSINESS_MEMBERS_SUCCESS = "BUSINESS_MEMBERS_SUCCESS";
export const BUSINESS_MEMBERS_FAIL = "BUSINESS_MEMBERS_FAIL";

import React from "react";
import "./menu-manager.css";
import { useDispatch } from "react-redux";
import { postMenuAction } from "../../actions/businessAction";
import CategorySettings from "../CategorySettings";
import EditMenuSettings from "../EditMenuSettings";

export default function MenuManager() {
  const dispatch = useDispatch();
  return (
    <div className="menu-manager">
      <button onClick={() => dispatch(postMenuAction())}>
        הוספת תפריט חדש
      </button>
      <button>עריכת תפריט</button>
      <button>תזמון תפריטים</button>
      <CategorySettings />
      <EditMenuSettings />
    </div>
  );
}
